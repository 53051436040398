import React from 'react'
import {Link} from 'react-router-dom'
import logo1 from '../../images/work/1.png'
import logo2 from '../../images/work/2.png'
import OTPP from '../../images/ontarioTeachersPensionPlan.png'
import flipp from '../../images/flipp.png'
import plusgrade from '../../images/plusgrade.png'
import SectionTitle from '../SectionTitle/SectionTitle'

const Expriences = [
    {
        date: '2022 - 2023',
        logo: plusgrade,
        position: 'Software Engineer',
        companyName: 'Plusgrade – Montreal, Quebec',
        workFrom: '',
    },
    {
        date: '2020 - 2021',
        logo: OTPP,
        position: 'Software Development Internship',
        companyName: "Ontario Teacher's Pension Plan",
        workFrom: '(Remote)',
    },
]


const Exprience = (props) => {
    return (
        <div className="wpo-work-area section-padding" id="experience">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                        <div className="wpo-section-title">
                            <h2>Work Experience</h2>
                            <p>I have had the opportunity to work with leading organizations across diverse industries in Canada, contributing to the development of innovative and scalable software solutions</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-work-wrap">
                    {Expriences.map((exprience, exp) => (
                        <div className="wpo-work-item" key={exp}>
                            <ul>
                                <li className="date">{exprience.date}</li>
                                <li className="logo">
                                    <img src={exprience.logo} alt="" style={{borderRadius:"100%", maxWidth:"150px"}}/>
                                </li>
                                <li className="position">{exprience.position} <span>{exprience.companyName} <span>{exprience.workFrom}</span></span></li>
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className="shape-wk">
                <svg width="1500" height="1500" viewBox="0 0 1500 1500" fill="none">
                    <g opacity="0.45" filter="url(#filter0_f_39_4214)">
                        <circle cx="750" cy="750" r="200" />
                    </g>
                    <defs>
                        <filter id="filter0_f_39_4214" x="0" y="0" width="1500" height="1500"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="275" result="effect1_foregroundBlur_39_4212" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default Exprience;